@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6e6e6e rgb(44, 44, 44);
  font-family: 'Poppins', sans-serif;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  border-radius: 20px;
  border: 3px solid rgb(96, 96, 96);
}
